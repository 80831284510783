import React, { useState, useRef } from 'react';
import Navbar from './Navbar'
import Bgcontact from './Bgcontact'
import emailjs from '@emailjs/browser';
import Footer from './Footer';


const ContactUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0ay0d5p', 'template_pgug7nj', form.current, {
        publicKey: '-skRuNHoJkRU0eyuE',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsModalOpen(true);
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar />
      <div className='py-14 fade-in'>
      <Bgcontact />
      </div>
      

      <section className="py-24 lg:pt-24 pt-12 font-rubik bg-gray-100 fade-in">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-8">
            <div className="flex flex-col items-center lg:items-start mb-10 lg:mb-0">
              <h4 className="text-indigo-600 text-base font-medium leading-6 mb-4 lg:text-left text-center">Contact Us</h4>
              <h2 className="text-gray-900 font-manrope lg:text-4xl text-3xl font-semibold leading-10 mb-9 lg:text-left text-center">Reach Out To Us</h2>
              <form ref={form} onSubmit={sendEmail} className="w-full">
                <input
                  type="text"
                  className="w-full h-14 shadow-sm text-gray-600 placeholder-gray-400 text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none focus:border-indigo-600 focus:ring-1 focus:ring-indigo-600 py-2 px-4 mb-8"
                  name="user_name"
                  placeholder="Name"
                />
                <input
                  type="email"
                  className="w-full h-14 shadow-sm text-gray-600 placeholder-gray-400 text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none focus:border-indigo-600 focus:ring-1 focus:ring-indigo-600 py-2 px-4 mb-8"
                  name="user_email"
                  placeholder="Email"
                />
                <textarea
                  id="text"
                  className="w-full h-48 shadow-sm resize-none text-gray-600 placeholder-gray-400 text-lg font-normal leading-7 rounded-2xl border border-gray-200 focus:outline-none focus:border-indigo-600 focus:ring-1 focus:ring-indigo-600 px-4 py-4 mb-8"
                  name="message"
                  placeholder="Message"
                ></textarea>
                <button
                  type="submit"
                  className="w-full h-12 text-center text-white text-base font-semibold leading-6 rounded-full bg-indigo-600 shadow transition-transform transform hover:scale-105 duration-300 hover:bg-indigo-800"
                >
                  Submit
                </button>
              </form>

              {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white rounded-lg p-8 shadow-lg">
                    <h3 className="text-2xl font-semibold text-gray-900 mb-4">Success!</h3>
                    <p className="text-gray-600 mb-6">Your message has been sent successfully.</p>
                    <button
                      className="w-full h-10 text-center text-white text-base font-semibold leading-6 rounded-full bg-indigo-600 hover:bg-indigo-800"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="lg:max-w-xl w-full h-[600px] flex items-center justify-center bg-cover bg-no-repeat bg-center bg-[url('https://pagedone.io/asset/uploads/1696245837.png')] fade-in">
              <div className="lg:w-96 w-auto h-auto bg-white shadow-xl lg:p-6 p-4">
                <h1 className="font-bold text-5xl text-center mb-5">QaserAlWadi</h1>
                <a href="tel:+971501535256" className="flex items-center mb-6">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.3092 18.3098C22.0157 18.198 21.8689 18.1421 21.7145 18.1287C21.56 18.1154 21.4058 18.1453 21.0975 18.205L17.8126 18.8416C17.4392 18.9139 17.2525 18.9501 17.0616 18.9206C16.8707 18.891 16.7141 18.8058 16.4008 18.6353C13.8644 17.2551 12.1853 15.6617 11.1192 13.3695C10.9964 13.1055 10.935 12.9735 10.9133 12.8017C10.8917 12.6298 10.9218 12.4684 10.982 12.1456L11.6196 8.72559C11.6759 8.42342 11.7041 8.27233 11.6908 8.12115C11.6775 7.96998 11.6234 7.82612 11.5153 7.5384L10.6314 5.18758C10.37 4.49217 10.2392 4.14447 9.95437 3.94723C9.6695 3.75 9.29804 3.75 8.5551 3.75H5.85778C4.58478 3.75 3.58264 4.8018 3.77336 6.06012C4.24735 9.20085 5.64674 14.8966 9.73544 18.9853C14.0295 23.2794 20.2151 25.1426 23.6187 25.884C24.9335 26.1696 26.0993 25.1448 26.0993 23.7985V21.2824C26.0993 20.5428 26.0993 20.173 25.9034 19.8888C25.7076 19.6046 25.362 19.4729 24.6708 19.2096L22.3092 18.3098Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <h5 className="text-black text-base font-normal leading-6 ml-5">+971501535256</h5>
                </a>
                <a href="mailto:info@qaseralwadi.com" className="flex items-center mb-6">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.81501 8.75L10.1985 13.6191C12.8358 15.2015 14.1544 15.9927 15.6032 15.9582C17.0519 15.9237 18.3315 15.0707 20.8905 13.3647L27.185 8.75M12.5 25H17.5C22.214 25 24.5711 25 26.0355 23.5355C27.5 22.0711 27.5 19.714 27.5 15C27.5 10.286 27.5 7.92893 26.0355 6.46447C24.5711 5 22.214 5 17.5 5H12.5C7.78595 5 5.42888 5 3.96447 6.46447C2.5 7.92893 2.5 10.286 2.5 15C2.5 19.714 2.5 22.0711 3.96447 23.5355C5.42888 25 7.78595 25 12.5 25Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <h5 className="text-black text-base font-normal leading-6 ml-5">info@qaseralwadi.com</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
// Tailwind CSS (using inline style for fade-in)
const fadeInStyles = `
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fade-in 1s ease-in;
}
`;

// Append the style to the document
const styleElement = document.createElement("style");
styleElement.textContent = fadeInStyles;
document.head.appendChild(styleElement);

export default ContactUs;
