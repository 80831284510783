import React, { useEffect, useRef } from "react";
import Background from "./Background";
import Navbar from "./Navbar";
import icon from "../assets/icon.png";
import hand from "../assets/hand.png";
import asset from "../assets/Asset-9.png";
import build from "../assets/Asset-2.png";
import cater from "../assets/Asset-4.png";
import constr from "../assets/Asset-5.png";
import asst from "../assets/Asset-6.png";
import expert from "../assets/expertise_services.webp";
import client from "../assets/client.webp";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Reel from "./Reel";


// Card Component
const Card = ({ number, title, content, linkColor }) => {
  return (
    <div className="relative min-w-[320px] h-[440px] shadow-[inset_5px_5px_5px_rgba(0,0,0,0.2),inset_-5px_-5px_15px_rgba(255,255,255,0.1),5px_5px_15px_rgba(0,0,0,0.3),-5px_-5px_15px_rgba(255,255,255,0.1)] rounded-[15px] m-[30px] transition-transform duration-500">
      <div className="absolute top-[20px] left-[20px] right-[20px] bottom-[20px] bg-[#2a2b2f] rounded-[15px] flex justify-center items-center overflow-hidden transition-transform duration-500 hover:-translate-y-[50px]">
        <div className="relative p-[20px] text-center">
          <h2 className="absolute top-[-10px] right-[30px] text-[8rem] text-[rgba(255,255,255,0.1)]">
            {number}
          </h2>
          <h3 className="text-[1.8rem] text-white mb-[15px] z-10 transition-all duration-500">
            {title}
          </h3>
          <p className="text-[1rem] font-light text-[rgba(255,255,255,0.9)] z-10 transition-all duration-500">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

// LandingPage Component
const LandingPage = () => {
  
  const navigate = useNavigate();
  {
    /* Mission */
  }

  const handleClick = () => {
    navigate("/Projects"); // Replace with your target route
  };
  const listItems = [
    { text: "Expo City Dubai", href: "https://www.expocitydubai.com/en/" },
    { text: "One Za'abeel Residence", href: "https://www.onezaabeel.com/" },
    { text: "Dubai Hills Mall", href: "https://www.dubaihillsmall.ae/" },
    {
      text: "Yas Waterworld Abu Dhabi",
      href: "https://www.yaswaterworld.com/",
    },
  ];

  const Card = ({ number, title, content, linkColor }) => {
    return (
      <div className="relative min-w-[320px] h-[440px] shadow-[inset_5px_5px_5px_rgba(0,0,0,0.2),inset_-5px_-5px_15px_rgba(255,255,255,0.1),5px_5px_15px_rgba(0,0,0,0.3),-5px_-5px_15px_rgba(255,255,255,0.1)] rounded-[15px] m-[30px] transition-transform duration-500 fadi-in">
        <div className="absolute top-[20px] left-[20px] right-[20px] bottom-[20px] bg-[#2a2b2f] rounded-[15px] flex justify-center items-center overflow-hidden transition-transform duration-500 hover:-translate-y-[50px]">
          <div className="relative p-[20px] text-center">
            <h2 className="absolute top-[-10px] right-[30px] text-[8rem] text-[rgba(255,255,255,0.1)]">
              {number}
            </h2>
            <h3 className="text-[1.8rem] text-white mb-[15px] z-10 transition-all duration-500">
              {title}
            </h3>
            <p className="text-[1rem] font-light text-[rgba(255,255,255,0.9)] z-10 transition-all duration-500">
              {content}
            </p>
            {/* <a 
            href="#" 
            className={relative inline-block px-[20px] py-[8px] bg-[${linkColor}] rounded-[5px] text-white mt-[20px] shadow-[0_10px_20px_rgba(0,0,0,0.2)] transition-all duration-500 hover:shadow-[0_10px_20px_rgba(0,0,0,0.6)] hover:bg-white hover:text-black}
          >
            Read More
          </a> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar></Navbar>
      <div className="fade-in py-14">
        <Background></Background>
      </div>
      <div className="flex flex-col md:flex-row gap-6 justify-center  items-center font-rubik fade-in">
        <div className="">
          <div className="list">
            <h2 className="text-black font-bold tracking-wide font-yanone text-5xl mb-2">
              Our Delivered Projects
            </h2>
            <ul>
              {listItems.map((item, index) => (
                <li
                  key={index}
                  className="group relative pl-3 border-l-2 border-gray-950 text-black font-montserrat transition-all duration-500 cursor-pointer"
                >
                  <a
                    href={item.href}
                    className="relative z-10 inline-block py-2 pl-3 transition-all duration-500"
                  >
                    {item.text}
                  </a>
                  <div className="absolute inset-0 bg-orange-500 transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100" />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            <button
              onClick={handleClick}
              className="w-80 bg-orange-500 border border-red-800 px-4 py-3 text-white font-bold tracking-wider text-center rounded-md cursor-pointer transition-colors duration-300 hover:bg-orange-700 hover:shadow-md active:shadow-inner"
            >
              SHOW MORE
            </button>
          </div>
        </div>
        {/* Card 1 */}

        <div className="flip-card w-80 md:w-64 h-64">
          <div className="flip-card-inner">
            <div className="flip-card-front bg-[#ede8f5] p-4 rounded-lg shadow-lg">
              <img src={icon} className="h-16 mx-auto" alt="Icon" />
              <h1 className="mt-5 font-semibold text-4xl md:text-5xl text-center">
                500+
              </h1>
              <p className="mt-2 font-thin text-lg md:text-xl text-center">
                Workforce
              </p>
            </div>
            <div className="flip-card-back bg-blue-600 text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
              <h1 className="font-mono text-2xl md:text-4xl text-center">
                Employees
              </h1>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        {/* <div className="flip-card w-80 md:w-64 h-64">
          <div className="flip-card-inner">
            <div className="flip-card-front bg-white p-4 rounded-lg shadow-lg">
              <img src={truck} className="h-16 mx-auto" alt="Truck" />
              <h1 className="mt-5 font-semibold text-4xl md:text-5xl text-center">
                5000+
              </h1>
              <p className="mt-2 font-thin text-lg md:text-xl text-center">
                Equipment
              </p>
            </div>
            <div className="flip-card-back bg-blue-600 text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
              <h1 className="font-mono text-2xl md:text-4xl text-center">
                In-House Equipment
              </h1>
            </div>
          </div>
        </div> */}

        {/* Card 3 */}
        <div className="flip-card w-80 md:w-64 h-64">
          <div className="flip-card-inner">
            <div className="flip-card-front bg-white p-4 rounded-lg shadow-lg">
              <img src={hand} className="h-16 mx-auto" alt="Hand" />
              <h1 className="mt-5 font-semibold text-4xl md:text-5xl text-center">
                50+
              </h1>
              <p className="mt-2 font-thin text-lg md:text-xl text-center">
                Satisfied Clients
              </p>
            </div>
            <div className="flip-card-back bg-blue-600 text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
              <h1 className="font-mono text-2xl md:text-4xl text-center">
                Satisfied Clients
              </h1>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        {/* <div className="flip-card w-80 md:w-64 h-64">
          <div className="flip-card-inner">
            <div className="flip-card-front bg-white p-4 rounded-lg shadow-lg">
              <img src={flag} className="h-16 mx-auto" alt="Flag" />
              <h1 className="mt-5 font-semibold text-4xl md:text-5xl text-center">
                6
              </h1>
              <p className="mt-2 font-thin text-lg md:text-xl text-center">
                Business Segments
              </p>
            </div>
            <div className="flip-card-back bg-blue-600 text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
              <h1 className="font-mono text-2xl md:text-4xl text-center">
                Business Segments
              </h1>
            </div>
          </div>
        </div> */}
      </div>

      <div className="bg-slate-400 max-w-full h-auto mt-44 px-4 py-10 font-rubik fade-in">
        <h1 className="text-white text-center text-5xl pt-6 font-bold">
          Our Strategic Business Segment
        </h1>

        <div className="flex flex-wrap justify-center gap-10 mt-10">
          {/* 1st Service */}
          <div className="rounded-2xl border border-coral-500 h-32 w-72 flex flex-col items-center justify-center">
            <img src={asset} className="h-16" alt="Industrial Services" />
            <p className="text-white text-center text-xl font-medium mt-2">
              Industrial Services
            </p>
          </div>
          {/* 3rd Service */}
          <div className="rounded-2xl border border-coral-500 h-32 w-72 flex flex-col items-center justify-center">
            <img src={build} className="h-16" alt="Manufacturing Building" />
            <p className="text-white text-center text-xl font-medium mt-2">
              Manufacturing Building
            </p>
          </div>
          {/* 4th Service */}
          <div className="rounded-2xl border border-coral-500 h-32 w-72 flex flex-col items-center justify-center">
            <img
              src={cater}
              className="h-16"
              alt="Facility Management Services"
            />
            <p className="text-white text-center text-xl font-medium mt-2">
              Facility Management Services
            </p>
          </div>
          {/* 5th Service */}
          <div className="rounded-2xl border border-coral-500 h-32 w-72 flex flex-col items-center justify-center">
            <img
              src={constr}
              className="h-16"
              alt="Civil Construction Services"
            />
            <p className="text-white text-center text-xl font-medium mt-2">
              Civil Construction Services
            </p>
          </div>
          {/* 6th Service */}
          <div className="rounded-2xl border border-coral-500 h-32 w-72 flex flex-col items-center justify-center">
            <img
              src={asst}
              className="h-16"
              alt="Trading Industrial Materials"
            />
            <p className="text-white text-center text-xl font-medium mt-2">
              Trading Industrial Materials
            </p>
          </div>
        </div>
      </div>

      {/* </div> */}
      {/* Cards */}
      {/* Mission */}
      <div className="flex justify-center items-center flex-wrap max-w-[1200px] m-[40px_0] font-rubik fade-in">
        <Card
          number="01"
          title="Vision"
          content="To be the best & most respected industrial service provider striving to reflect the highest ethical and professional standards that can deliver a world class service to clients."
          linkColor="#2196f3"
        />
        <Card
          number="02"
          title="Mission"
          content="To pursue excellence in all operations by improving quality standards and procedures and developing competitive people that can best render a world class service to clients."
          linkColor="#e91e63"
        />
        <Card
          number="03"
          title="Values"
          content="By upholding basic human values, respecting nature and helping the weak and disadvantaged through several activities, we hope to develop the new chain of QasarAlWadi team."
          linkColor="#23c186"
        />
      </div>
      {/* Animated Text */}

      {/* <div className=" text-[#011a32] text-[16px] leading-[1.25] font-raleway text-center h-64 flex items-center justify-center fade-in">
        <div id="wrapper" className="max-w-[80em] mx-auto">
          <div
            id="container"
            className="flex flex-col justify-center min-h-screen p-4 w-full"
          >
            <h1 className="text-center text-[2.5em] sm:text-[3.5em] md:text-[4em] lg:text-[5em] font-black leading-[1.1] animate-text-shadow hover:pause-animation mb-10 md:mb-20">
              64 Million Safe Man Hours Without Lost Time Injury
            </h1>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col md:flex-row gap-8 md:gap-16 p-4 font-rubik fade-in ">
        <div className="w-full md:w-1/2 h-96 flex items-center justify-center">
          <img
            className="w-full h-full object-cover hover:scale-110 transition-all duration-500 cursor-pointer"
            src={expert}
            alt="Expert"
          />
        </div>
        <div className="text-center md:text-left md:pl-8">
          <h1 className="text-orange-500 font-bold text-2xl md:text-3xl font-serif">
            On Demand, Anything And Everything
          </h1>
          <p className="text-blue-800 text-3xl md:text-5xl font-serif italic mt-6 md:mt-8">
            You name the service, QaserAlWadi has it!
          </p>
          <p className="font-semibold text-sm md:text-base mt-6 md:mt-10">
            QaserAlWadi goes above and beyond to create a fantastic experience.
            <br />
            Perfectly crafted to suit your Industrial Needs with almost
            unlimited
            <br />
            options to execute your projects. QasarAlWadi serves a variety of
            sectors
            <br />
            including Petrochemical, Oil & Gas, Power Generation, and other
            Industrial divisions.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-screen font-rubik fade-in p-4">
      <h1 className="font-bold font-sans text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center text-orange-500">
        Join The 50+ Satisfied QaserAlWadi Customers!
      </h1>
      
      <div className="mt-5  w-full max-w-6xl">
        <Reel />
        {/* <img src={client} className="w-4/5" alt="Client"/> */}
      </div>
    </div>

      <Footer></Footer>
    </>
  );
};

// Tailwind CSS (using inline style for fade-in)
const fadeInStyles = `
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fade-in 1s ease-in;
}
`;

// Append the style to the document
const styleElement = document.createElement("style");
styleElement.textContent = fadeInStyles;
document.head.appendChild(styleElement);

export default LandingPage;
