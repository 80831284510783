import React, { useRef, useState } from "react";
import contact from "../assets/contact.mp4";
const VideoBackground = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleVideoToggle = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    // <div class="main">
    <div className="relative h-screen w-full overflow-hidden">
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        className="absolute inset-0 h-full w-full object-cover"
      >
        <source src={contact} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white p-5 font-rubik">
        <div className="text-center max-w-3xl">
          <h1 className="text-4xl sm:text-6xl font-bold underline  decoration-orange-500 underline-offset-8">
            Our Services
          </h1>
          <p className="text-xl sm:text-3xl font-bold mt-4">
            One Vision. Extensive Solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
