import React from "react";
import Navbar from "./Navbar";
import BackgroundAbout from "./BackgroundAbout";
import chairman from "../assets/Mr-Ayub.png";
import helmet from "../assets/helmet.png";
import handab from "../assets/handab.png";
import jacket from "../assets/jacket.png";
import crop from "../assets/cropped.png";
import Footer from "./Footer";
import map from "../assets/3746.jpg";
const About = () => {
  return (
    <>
      <Navbar></Navbar>
      <div className="fade-in py-14">
        <BackgroundAbout></BackgroundAbout>
      </div>
      <div className="mt-7 font-rubik fade-in">
        <h1 className="text-5xl text-center font-bold text-blue-500 pt-10 underline underline-offset-[12px] decoration-4 decoration-blue-500 drop-shadow-lg">
          Who We Are
        </h1>
        {/* <img src={crop} className='mx-auto mt-6 w-full max-w-[80px] h-auto' alt='Company Image' /> */}
        <p className="font-sans text-xl mt-9 text-center pl-8 pr-8 mb-9">
        Established in 2016, QaserAlWadi has emerged as a prominent industrial and Construction service provider in Dubai and the UAE. We offer a wide range of skilled
        manpower services across key industrial  construction sectors, including petrochemical, oil & gas, fertilizer, steel, cement, water treatment, and power generation.
        Headquartered in United Arab Emirates.
        </p>
      </div>

      <div className="flex place-content-center fade-in">
        <div>
          <div class="wrap">
            <div class="col col1"></div>
            <div class="col col2">
              <div class="circle circle1">
                <h2>
                  <span>
                    <b>Humble</b> <i>Begining</i>
                  </span>
                </h2>
                <div class="shim shim1"></div>
              </div>
              <div class="content">
                <p>
                QaserAlWadi was founded in 2016 as Construction and Industrial 
                Services Company in U.A.E. With limited equipment and a handful of clients,
                QaserAlWadi has grown steadily throughout the years
                </p>
              </div>
            </div>
            <div class="col col1">
              <div class="circle circle2">
                <h2>
                  <span>
                    <b>Evolving</b> <i>Market</i>
                  </span>
                </h2>
                <div class="shim shimx shim2"></div>
              </div>
              <div class="content">
                <p>
                  In 2017, QaserAlWadi added Skilled Manpower supply based on
                  the needs of the market
                </p>
              </div>
            </div>
            <div class="col col2"></div>
            <div class="col col1"></div>
            <div class="col col2">
              <div class="circle circle3">
                <h2>
                  <span>
                    <b>Transportation</b> <i>Service</i>
                  </span>
                </h2>
                <div class="shim shim3"></div>
              </div>
              <div class="content">
                <h3>2018</h3>
                <p>
                  In 2018, Transportation services were added to complement
                  Manpower services.
                </p>
              </div>
            </div>
            <div class="col col1">
              <div class="circle circle4">
                <h2>
                  <span>
                    <b>Accelerating</b> <i>Growth</i>
                  </span>
                </h2>
                <div class="shim shimx shim4"></div>
              </div>
              <div class="content">
                <p>
                  After 3 years of successfully serving the heavy equipment and
                  transportation needs in the U.A.E, QaserAlWadi ventured into 5
                  new verticals to meet the needs of our large client base. By
                  2018, QaserAlWadi Contracting Company had achieved an enviable
                  position in U.A.E market
                </p>
              </div>
            </div>
            <div class="col col2"></div>
            <div class="col col1"></div>
            <div class="col col2">
              <div class="circle circle5">
                <h2>
                  <span>
                    <b>Prominent</b> <i>Status</i>
                  </span>
                </h2>
                <div class="shim shim5"></div>
              </div>
              <div class="content">
                <h3>2019</h3>
                <p>
                  By 2019, QaserAlWadi had become a prominent industrial service
                  provider, serving a variety of sectors including
                  petrochemical, oil & gas, fertilizer, power generation, steel,
                  cement, and water treatment.
                </p>
              </div>
            </div>
            <div class="col col1">
              <div class="circle circle6">
                <h2>
                  <span>
                    <b>Towards</b> <i>Brighter</i>
                  </span>
                </h2>
                <div class="shim shimx shim6"></div>
              </div>
              <div class="content">
                <h3>Now</h3>
                <p>
                  With the growing industrial needs, our roadmap is set to widen
                  our services to other geographical areas. We’re currently
                  working on projects in Dubai, Indonesia, Vietnam, Kuwait and
                  more
                </p>
              </div>
            </div>
            <div class="col col2"></div>
            <div class="col col1"></div>

            <div class="col col2"></div>
          </div>
        </div>
      </div>
      {/* Directors */}
      <div className="h-full w-full  mt-16 pb-16 fade-in">
        <h1 className="text-5xl text-center font-bold text-blue-500 pt-10 underline underline-offset-[12px] decoration-4 decoration-blue-500 drop-shadow-lg font-rubik">
          Our Director
        </h1>

        <div className="flex flex-col md:flex-row gap-10 mt-16 justify-center items-center">
          <div className="bg-slate-400 w-4/5 md:w-1/3 h-auto p-6 rounded-lg">
            <img
              className="border hover:scale-110 transition-all duration-500 cursor-pointer rounded-full w-60 md:w-80 mx-auto"
              src={chairman}
              alt="Chairman"
            />
            <h1 className="text-center mt-5 font-semibold text-2xl md:text-3xl text-violet-900 font-rubik">
              Mr. Muhammad Ayub Khan
            </h1>
            <h1 className="text-center mt-2 text-lg text-black font-rubik">
              Chairman & CEO
            </h1>
            <p className="text-center text-sm md:text-base font-rubik">
              Mr. Muhammad Ayub Khan serves as the President & CEO and is
              responsible for the overall Planning, Organizational Management,
              Finance and Production operations.
            </p>
          </div>
        </div>
      </div>

      {/* Why Us */}

      <div className="fade-in">
        <section
          id="features"
          class="relative px-6 py-10 md:py-20 md:px-10 border-t border-b border-neutral-900 "
        >
          <div class="relative mx-auto max-w-5xl text-center">
            <span class="my-3 flex items-center justify-center font-medium uppercase tracking-wider text-slate-900 font-rubik">
              Why choose us
            </span>
            <h2 class="block w-full bg-gradient-to-b from-slate-900 to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl md:text-5xl font-rubik">
              Build your Dream With Us
            </h2>
            <p class="mx-auto my-4 w-full max-w-xl font-medium leading-relaxed tracking-wide text-black font-rubik">
              Our aim is to continuously exceed the expectations of our client
              to deliver quality construction. Our team members verify all
              features of work. Safety.
            </p>
          </div>

          <div class="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
            <div class="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-md border bg-gradient-to-b from-blue-600 to-blue-800 border-blue-700">
                <img src={handab} alt="Work Excellence" />
              </div>
              <h3 class="mt-6 text-black text-xl sm:text-2xl font-rubik">
                Work Excellence
              </h3>
              <p class="my-4 mb-0 font-normal leading-relaxed tracking-wide text-black text-sm sm:text-base font-rubik">
                8+ Years Of Excellence
              </p>
            </div>

            <div class="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-md border bg-gradient-to-b from-blue-600 to-blue-800 border-blue-700">
                <img src={jacket} alt="Safety" />
              </div>
              <h3 class="mt-6 text-black text-xl sm:text-2xl font-rubik">
                Safety
              </h3>
              <p class="my-4 mb-0 font-normal leading-relaxed tracking-wide text-black text-sm sm:text-base font-rubik">
                53M + Safe Man Hours
              </p>
            </div>

            <div class="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-md border bg-gradient-to-b from-blue-600 to-blue-800 border-blue-700">
                <img src={helmet} alt="Equipments" />
              </div>
              <h3 class="mt-6 text-black text-xl sm:text-2xl font-rubik">
                Equipments
              </h3>
              <p class="my-4 mb-0 font-normal font-rubik leading-relaxed tracking-wide text-black text-sm sm:text-base ">
                7500+ Pieces Of Equipments
              </p>
            </div>
          </div>

          <div class="absolute bottom-0 left-0 z-0 h-1/4 w-full border-b border-transparent "></div>
          <div class="absolute bottom-0 right-0 z-0 h-1/4 w-full "></div>
        </section>
      </div>

      {/* Safety */}
      <div className="bg-slate-400 h-2/3 fade-in">
        <h1 className="text-5xl text-black font-bold text-center pt-10  shadow-lg font-montserrat">
          Committed To Safety
        </h1>
        <p className="text-lg text-black text-justify pl-7 pr-7 pt-9 font-josefin">
          Our basic principle is to ensure a safe and healthy environment for
          our employees. We’re committed to providing mandatory PPE to all our
          employees in compliance with the minimum required standards of our
          clients. We ensure a safe and healthy workplace to avoid accidents,
          injuries, and illnesses. We provide adequate training and information
          on existing and new safety procedures. QaserAlWadi ascertains
          systematic inspection and reliability of resources to evade potential
          hazards and risks. We implement policies and procedures along with
          proper documentation and investigation in case of accidents or
          unforeseen circumstances.
        </p>
        <p className="font-bold text-xl pl-7 pt-7 text-black font-montserrat">
          Effective certified{" "}
        </p>
        <p className="font-medium text-xl pl-7  text-black font-josefin">
          occupational health & safety management system
        </p>
        <p className="text-lg text-black text-justify pl-7 font-josefin">
          Complying with the requirements of ISO 45001:2018 (OHSMS)
        </p>
        <h1 className="font-bold text-xl pl-7 pt-7 text-black font-montserrat">
          Safety training- in house
        </h1>
        <p className="text-lg text-black text-justify pl-7 font-josefin">
          Training academy that provides in-house training for the development
          of technical skill as well as EHS training requirements
        </p>
      </div>
      <div className="">
        <h1 className="text-5xl text-center font-bold text-blue-500 pt-10 underline underline-offset-[12px] decoration-4 decoration-blue-500 drop-shadow-lg font-rubik">
          Our Presence
        </h1>

        <div className="flex justify-center mt-8 px-4 py-6 fade-in">
          <img
            src={map}
            className="w-full max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl rounded-lg shadow-lg object-cover"
            alt="Branch Locations"
          />
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};
// Tailwind CSS (using inline style for fade-in)
const fadeInStyles = `
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fade-in 1s ease-in;
}
`;

// Append the style to the document
const styleElement = document.createElement("style");
styleElement.textContent = fadeInStyles;
document.head.appendChild(styleElement);
export default About;
