import React from "react";
import cropped from '../assets/cropped.png'
const PreLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
    <div className="relative w-[120px] h-[120px]">
      <div className="border-[16px] border-gray-200 border-t-[16px] border-t-slate-800 rounded-full w-full h-full animate-spin"></div>
      <img
        src={cropped}
        alt="Logo"
        className="absolute top-0 left-0 w-full h-full"
      />
    </div>
  </div>
  );
};

export default PreLoader;
