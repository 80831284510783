import React, { useRef, useState } from "react";
import vid from "../assets/vid.mp4";
const VideoBackground = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleVideoToggle = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    // <div class="main">
    <div className="relative h-screen w-full overflow-hidden">
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        className="absolute inset-0 h-full w-full object-cover"
      >
        <source src={vid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white p-5 content-center font-rubik ">
        <div class="flex flex-row min-h-screen justify-center items-center">
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
  <h1 className="text-white text-4xl md:text-6xl font-noto-nastaliq font-bold text-center underline underline-offset-8 decoration-orange-500 mb-6">
    قصر الوادي
  </h1>
  <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-rubik py-4 md:py-8 text-slate-200 font-medium text-center mb-6">
    A Commitment To Innovation & Sustainability
  </p>
  
  <button
    type="button"
    className="px-6 py-3.5 text-base font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg"
  >
    Read More
  </button>
</div>


          {/* <h1 className="text-3xl ">Heading</h1>
        <p className="mt-9">Lorem ipsum...</p> */}
        </div>
      </div>
      //{" "}
    </div>
  );
};

export default VideoBackground;
