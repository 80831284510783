import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import About from './Components/About';
import ContactUs from './Components/ContactUs';
import Projects from './Components/Projects'
import OurService from './Components/OurService';
import Preloader from './Components/Preloader'

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading period
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
  {loading ? (
        <Preloader />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/LandingPage" replace />} />
            <Route path="/LandingPage" element={<LandingPage />} />
            <Route path="/About" element={<About />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/OurService" element={<OurService />} />
            <Route path="/Projects" element={<Projects />} />
        
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;

