import React, { useState } from "react";
import imgg from "../assets/logooo.webp";
import { NavLink, Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
    <nav className="bg-white bg-opacity-30 backdrop-blur-lg shadow-lg fixed w-full top-0 z-50">
      <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between space-x-8">
        {/* Left Navigation Links (Desktop) */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:space-x-8 animate-slideInLeft">
          <ul className="flex space-x-8">
            <li>
              <NavLink
                to="/LandingPage"
                className="font-bold font-rubik no-underline hover:underline underline-offset-8 text-slate-900 dark:text-white transition-colors duration-300 ease-in-out hover:text-blue-500 dark:hover:text-blue-400"
                activeClassName="text-blue-700 dark:text-blue-500"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/OurService"
                className="font-bold font-rubik no-underline hover:underline underline-offset-8 text-slate-900 dark:text-white transition-colors duration-300 ease-in-out hover:text-blue-500 dark:hover:text-blue-400"
                activeClassName="text-blue-700 dark:text-blue-500"
              >
                Our Services
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Logo */}
        <div className="flex justify-center mx-auto lg:mx-0 animate-fadeIn">
          <Link
            to="/LandingPage"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={imgg} className="h-12" alt="Logo" />
          </Link>
        </div>

        {/* Right Navigation Links (Desktop) */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-start lg:space-x-8 animate-slideInRight">
          <ul className="flex space-x-8">
            <li>
              <NavLink
                to="/About"
                className="font-bold font-rubik no-underline hover:underline underline-offset-8 text-slate-900 dark:text-white transition-colors duration-300 ease-in-out hover:text-blue-500 dark:hover:text-blue-400"
                activeClassName="text-blue-700 dark:text-blue-500"
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/ContactUs"
                className="font-bold font-rubik no-underline hover:underline underline-offset-8 text-slate-900 dark:text-white transition-colors duration-300 ease-in-out hover:text-blue-500 dark:hover:text-blue-400"
                activeClassName="text-blue-700 dark:text-blue-500"
              >
                Contact Us
              </NavLink>
            </li>
            
          </ul>
        </div>

        {/* Toggle Button for Mobile View */}
        <button
          className="block lg:hidden ml-auto p-2 text-gray-900 dark:text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
    </nav>

    {/* Mobile Sidebar */}
    {isOpen && (
      <div className="lg:hidden fixed top-0 right-0 h-full w-3/4 max-w-xs bg-gray-800 z-40 transform transition-transform duration-300 ease-in-out">
        <nav className="h-full flex flex-col justify-center items-center">
          <ul className="space-y-4 text-center ">
            <li>
              <NavLink
                to="/LandingPage"
                className="block text-white text-xl py-2 px-4 hover:bg-blue-600 "
                activeClassName="bg-blue-600"
                onClick={() => setIsOpen(false)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/About"
                className="block text-white text-xl py-2 px-4 hover:bg-blue-600"
                activeClassName="bg-blue-600"
                onClick={() => setIsOpen(false)}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/OurService"
                className="block text-white text-xl py-2 px-4 hover:bg-blue-600"
                activeClassName="bg-blue-600"
                onClick={() => setIsOpen(false)}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/ContactUs"
                className="block text-white text-xl py-2 px-4 hover:bg-blue-600"
                activeClassName="bg-blue-600"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* Close button */}
        <button
          className="absolute top-4 right-4 text-white"
          onClick={() => setIsOpen(false)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
    )}
  </>
);
};

export default Navbar;
 
