import React, { useRef, useState } from "react";
import about from "../assets/about.mp4";
const VideoBackground = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleVideoToggle = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    // <div class="main">
    <div className="relative h-screen w-full overflow-hidden">
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        className="absolute inset-0 h-full w-full object-cover"
      >
        <source src={about} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white p-5 content-center  place-content-center font-rubik">
        <div class="flex flex-row min-h-screen justify-center items-center">
          <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
            <h1 className="text-white text-4xl md:text-6xl font-bold underline underline-offset-8 decoration-orange-500">
              ABOUT US
            </h1>
            <p className="text-white text-xl md:text-3xl font-bold mt-4 md:mt-7">
              One Vision. Extensive Solutions.
            </p>
            <button
              type="button"
              className="px-6 py-3.5 mt-6 md:mt-7 text-base font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg"
            >
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
