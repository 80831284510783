import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import zab1 from '../assets/one-zaabeel-1.jpg';
import zab2 from '../assets/one-zaabeel-2.jpg';
import zab3 from '../assets/one-zaabeel-3.jpg';
import zab4 from '../assets/one-zaabeel-4.jpg';
import dub1 from '../assets/dubai-hills-mall-1.jpg';
import dub2 from '../assets/dubai-hills-mall-2.jpg';
import dub3 from '../assets/dubai-hills-mall-3.jpg';
import dub4 from '../assets/dubai-hills-mall-4.jpg';
import exp1 from '../assets/expo-1.jpg';
import exp2 from '../assets/expo-2.jpg';
import exp3 from '../assets/expo-3.jpg';
import exp4 from '../assets/expo-4.jpg'; // Corrected the image name
import Footer from './Footer';

const Slider = ({ slides, currentIndex, goToSlide, prevSlide, nextSlide }) => (
  <div className="relative w-full md:w-1/2 h-96 mb-10 md:mb-0">
    <div className="relative h-full overflow-hidden rounded-lg">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
          data-carousel-item
        >
          <img
            src={slide}
            className="w-full h-full object-cover"
            alt={`Slide ${index + 1}`}
          />
        </div>
      ))}
    </div>

    {/* Slider Indicators */}
    <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-3 z-30">
      {slides.map((_, index) => (
        <button
          key={index}
          type="button"
          className={`w-3 h-3 rounded-full ${
            index === currentIndex ? 'bg-white' : 'bg-gray-500'
          }`}
          aria-current={index === currentIndex ? 'true' : 'false'}
          aria-label={`Slide ${index + 1}`}
          onClick={() => goToSlide(index)}
        ></button>
      ))}
    </div>

    {/* Slider Controls */}
    <button
      type="button"
      className="absolute top-1/2 left-4 transform -translate-y-1/2 z-30 flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 hover:bg-opacity-50 focus:outline-none"
      onClick={prevSlide}
    >
      <span className="flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 hover:bg-opacity-50 focus:outline-none">
        <svg
          className="w-4 h-4 text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 1 1 5l4 4"
          />
        </svg>
        <span className="sr-only">Previous</span>
      </span>
    </button>

    <button
      type="button"
      className="absolute top-1/2 right-4 transform -translate-y-1/2 z-30 flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 hover:bg-opacity-50 focus:outline-none"
      onClick={nextSlide}
    >
      <span className="flex items-center justify-center w-10 h-10 rounded-full bg-white bg-opacity-30 hover:bg-opacity-50 focus:outline-none">
        <svg
          className="w-4 h-4 text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 9 4-4-4-4"
          />
        </svg>
        <span className="sr-only">Next</span>
      </span>
    </button>
  </div>
);

const Main = () => {
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0); // Added state for the third slider

  const slides1 = [zab1, zab2, zab3, zab4];
  const slides2 = [dub1, dub2, dub3, dub4];
  const slides3 = [exp1, exp2, exp3, exp4];

  const goToSlide1 = (index) => setCurrentIndex1(index);
  const prevSlide1 = () => setCurrentIndex1((prevIndex) => (prevIndex === 0 ? slides1.length - 1 : prevIndex - 1));
  const nextSlide1 = () => setCurrentIndex1((prevIndex) => (prevIndex === slides1.length - 1 ? 0 : prevIndex + 1));

  const goToSlide2 = (index) => setCurrentIndex2(index);
  const prevSlide2 = () => setCurrentIndex2((prevIndex) => (prevIndex === 0 ? slides2.length - 1 : prevIndex - 1));
  const nextSlide2 = () => setCurrentIndex2((prevIndex) => (prevIndex === slides2.length - 1 ? 0 : prevIndex + 1));

  const goToSlide3 = (index) => setCurrentIndex3(index);
  const prevSlide3 = () => setCurrentIndex3((prevIndex) => (prevIndex === 0 ? slides3.length - 1 : prevIndex - 1));
  const nextSlide3 = () => setCurrentIndex3((prevIndex) => (prevIndex === slides3.length - 1 ? 0 : prevIndex + 1));

  useEffect(() => {
    const interval1 = setInterval(nextSlide1, 3000);
    return () => clearInterval(interval1);
  }, [nextSlide1]);

  useEffect(() => {
    const interval2 = setInterval(nextSlide2, 3000);
    return () => clearInterval(interval2);
  }, [nextSlide2]);

  useEffect(() => {
    const interval3 = setInterval(nextSlide3, 3000);
    return () => clearInterval(interval3);
  }, [nextSlide3]); // Corrected dependency here

  return (
    <>
      <Navbar />
      
      <div className="flex flex-col md:flex-row items-center justify-between p-4 mt-20 bg-neutral-500">
        <Slider
          slides={slides1}
          currentIndex={currentIndex1}
          goToSlide={goToSlide1}
          prevSlide={prevSlide1}
          nextSlide={nextSlide1}
        />
        <div className="w-full md:w-1/2 text-center md:text-left md:pl-10">
          <h1 className='text-5xl font-yanone text-white'>One Za'abeel Residence</h1>
          <p className='text-slate-100 font-sans font-medium mt-5'>
            One Za’abeel represents the ultimate address for the modern urban citizen, located at the heart of one of the most dynamic cities in the world. One Za’abeel has been designed to incorporate the world’s best living, working and leisure experiences in the heart of Dubai, to create something simply spectacular and everlasting.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between p-4 mt-6 bg-neutral-600">
        <div className="w-full md:w-1/2 text-center md:text-left md:pr-10">
          <h1 className='text-5xl font-yanone text-white'>Dubai Hills Mall</h1>
          <p className='text-slate-100 font-sans font-medium mt-5'>
            Dubai Hills Mall represents a premium retail destination located in the heart of Dubai. It is designed to offer an unparalleled shopping, dining, and entertainment experience in a modern and sophisticated environment.
          </p>
        </div>
        <Slider
          slides={slides2}
          currentIndex={currentIndex2}
          goToSlide={goToSlide2}
          prevSlide={prevSlide2}
          nextSlide={nextSlide2}
        />
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between p-4 mt-6 mb-6 bg-gray-400">
        <Slider
          slides={slides3}
          currentIndex={currentIndex3}
          goToSlide={goToSlide3}
          prevSlide={prevSlide3}
          nextSlide={nextSlide3}
        />
        <div className="w-full md:w-1/2 text-center md:text-left md:pl-10">
          <h1 className='text-5xl font-yanone text-white'>Expo City Dubai</h1>
          <p className='text-slate-100 font-sans font-medium mt-5'>
          Expo City Dubai is a future-centric mini-city that builds on the legacy of Expo 2020 Dubai. The first World Expo to be held in the Middle East, Africa, and South Asia regions, it welcomed 192 nations and more than 24 million visitors during its run.
          

          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Main;
