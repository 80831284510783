import React from "react";
import insta from "../assets/insta.png";
import fb from "../assets/fb.png";
import ldn from "../assets/ldn.png";
import x from "../assets/x.png";
const Footer = () => {
  return (
    <>
      <div className="bg-slate-400 text-white p-4 sm:flex sm:gap-8 sm:justify-between max-w-full shadow-lg backdrop-blur-lg ">
        <div className="mb-8 sm:mb-0">
          <h1 className="font-medium mt-7 pl-5 text-slate-800 font-rubik text-lg ">
            Connect With Us
          </h1>
          <div className="flex mt-4 gap-4 pl-5">
            <a
              href="https://web.facebook.com/profile.php?id=61564408022572"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={fb}
                className="w-7 hover:opacity-80 transition-opacity duration-300"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={insta}
                className="w-7 hover:opacity-80 transition-opacity duration-300"
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/qaser-alwadi-90a6a7327"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ldn}
                className="w-7 hover:opacity-80 transition-opacity duration-300"
                alt="LinkedIn"
              />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={x}
                className="w-7 hover:opacity-80 transition-opacity duration-300"
                alt="Twitter"
              />
            </a>
          </div>
        </div>
        <div className="mb-8 sm:mb-0">
          <h1 className="mt-7 font-medium font-rubik text-slate-800 text-center sm:text-left text-lg">
            Know More About Us
          </h1>
        </div>
        <div className="text-center sm:text-left">
          <h1 className="pt-8 font-rubik text-slate-800 text-lg">QaserAlWadi</h1>
          <p className="font-rubik text-slate-800">#10353, Dubai U.A.E</p>
          <p className="font-rubik text-slate-800">
            Mobile:
            <a
              href="tel:+971501535256"
              className=" text-slate-800 font-rubik font-medium hover:underline"
            >
              +971501535256
            </a>
          </p>
          <p className="font-rubik text-slate-800">
            Email:
            <a
              href="mailto:info@qaseralwadi.com"
              className="text-slate-800 hover:underline font-rubik  font-medium "
            >
              info@qaseralwadi.com
            </a>
          </p>
          <p className="font-rubik  text-slate-800">Web: QaserAlWadi </p>
        </div>
      </div>

      <div className="bg-slate-400 backdrop-blur-lg border-t border-gray-700 py-4 shadow-lg">
        <p className="text-center font-rubik text-slate-800">
          © Copyright 2024 | QaserAlWadi | All Rights Reserved
        </p>
        <div className="flex mt-4 gap-4 justify-center">
          <a
            href="https://web.facebook.com/profile.php?id=61564408022572"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={fb}
              className="w-7 hover:opacity-80 transition-opacity duration-300"
              alt="Facebook"
            />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={insta}
              className="w-7 hover:opacity-80 transition-opacity duration-300"
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/qaser-alwadi-90a6a7327"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ldn}
              className="w-7 hover:opacity-80 transition-opacity duration-300"
              alt="LinkedIn"
            />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={x}
              className="w-7 hover:opacity-80 transition-opacity duration-300"
              alt="Twitter"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
