import React from "react";
import image1 from "../assets/samsung-logo.png";
import image2 from "../assets/Saudi.png";
import image3 from '../assets/jgc-logo.png';
import image4 from "../assets/frabi.png";
import image5 from "../assets/saipem-logo.png";
import image6 from '../assets/yasref.png';
import image7 from '../assets/mcdermott-logo.png';
import image8 from '../assets/tekfen.png'
const images = [image1, image2, image3, image4, image5, image6, image7, image8]; // Your image sources

const HorizontalReel = () => {
  return (
    <div className="relative overflow-hidden w-full">
      <div className="reel-containscer">
        <div className="reel">
          {images.map((img, index) => (
            <div key={index} className="image-container">
              <img src={img} alt={`Item ${index}`} className="image" />
            </div>
          ))}
          {images.map((img, index) => (
            <div key={`copy-${index}`} className="image-container">
              <img src={img} alt={`Item ${index}`} className="image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HorizontalReel;
