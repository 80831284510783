import React from "react";
import Navbar from "./Navbar";
import Bgservices from "./Bgservices";
import psd from "../assets/worker.webp";
import pmta_1 from "../assets/qu.webp";
import ezgif from "../assets/w-s-f.webp";
import Footer from "./Footer";

const OurService = () => {
  return (
    <>
      <Navbar></Navbar>
      <div className="fade-in py-14">
        <Bgservices></Bgservices>
      </div>
      <div className="ml-7 mt-7 sm:ml-5 sm:mt-5 md:ml-7 md:mt-7 fade-in">
        <h1 className="text-4xl sm:text-5xl md:text-6xl text-left underline decoration-orange-500 underline-offset-8 text-blue-900 font-bold font-rubik">
          Our Services
        </h1>
        <p className="mt-5 text-base sm:text-lg md:text-xl font-medium leading-relaxed text-black font-rubik">
        Qaser AlWadi is a progressive services provider company in Dubai and the UAE, recognized as an important 
        and trusted partner for its clients in the region. Since 2016, we have been dedicated to delivering services
        tailored to our customers' needs, resulting in steady growth and a strong reputation for high-quality services
        with exceptional customer satisfaction.

        </p>
        <p className="mt-4 text-base sm:text-lg md:text-xl font-medium leading-relaxed text-black font-rubik">
        Specializing in industrial, technological, and construction services, QaserAlWadi serves a diverse range of industries,
        including petrochemical installations, oil & gas facility installations, fertilizer, steel, cement, and water treatment.
        Our comprehensive solutions enable clients to leverage our expertise across multiple sectors.
        </p>
      </div>

      <div className="container mx-auto px-4 my-7 font-rubik fade-in">
        {/* Grid layout with responsive columns */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* 1st Card */}
              {/* 4th Card */}
              <div>
            <div className="card-list w-full max-w-[400px] mx-auto">
              <article className="card bg-white shadow-md hover:shadow-lg focus-within:shadow-lg transition ease-in-out duration-150 rounded-2xl overflow-hidden p-5 relative transform hover:translate-y-[-5px]">
                <figure className="card-image rounded-lg overflow-hidden">
                  <img
                    src={ezgif}
                    alt="An orange painted blue, cut in half laying on a blue background"
                    className="w-full h-auto"
                  />
                </figure>
                <div className="card-header mt-6 flex items-center justify-between">
                  <a
                    href="#"
                    className="font-semibold text-xl leading-5 pr-4 text-gray-900 relative focus:outline-none focus:ring focus:ring-offset-2"
                  >
                    Industrial Construction Services
                  </a>
                </div>
                <div className="card-footer mt-5 border-t border-gray-300 pt-5 flex items-center flex-wrap"></div>
              </article>
            </div>
          </div>

          {/* 2nd Card */}
          <div>
            <div className="card-list w-full max-w-[400px] mx-auto">
              <article className="card bg-white shadow-md hover:shadow-lg focus-within:shadow-lg transition ease-in-out duration-150 rounded-2xl overflow-hidden p-5 relative transform hover:translate-y-[-5px]">
                <figure className="card-image rounded-lg overflow-hidden">
                  <img
                    src={pmta_1}
                    alt="An orange painted blue, cut in half laying on a blue background"
                    className="w-full h-auto"
                  />
                </figure>
                <div className="card-header mt-6 flex items-center justify-between">
                  <a
                    href="#"
                    className="font-semibold text-xl leading-5 pr-4 text-gray-900 relative focus:outline-none focus:ring focus:ring-offset-2"
                  >
                    Civil Construction Services
                  </a>
                </div>
                <div className="card-footer mt-5 border-t border-gray-300 pt-5 flex items-center flex-wrap"></div>
              </article>
            </div>
          </div>

          {/* 3rd Card */}
          <div>
            <div className="card-list w-full max-w-[400px] mx-auto">
              <article className="card bg-white shadow-md hover:shadow-lg focus-within:shadow-lg transition ease-in-out duration-150 rounded-2xl overflow-hidden p-5 relative transform hover:translate-y-[-5px]">
                <figure className="card-image rounded-lg overflow-hidden">
                  <img
                    src={psd}
                    alt="An orange painted blue, cut in half laying on a blue background"
                    className="w-full h-auto"
                  />
                </figure>
                <div className="card-header mt-6 flex items-center justify-between">
                  <a
                    href="#"
                    className="font-semibold text-xl leading-5 pr-4 text-gray-900 relative focus:outline-none focus:ring focus:ring-offset-2"
                  >
                    Trading Industrial Material
                  </a>
                </div>
                <div className="card-footer mt-5 border-t border-gray-300 pt-5 flex items-center flex-wrap"></div>
              </article>
            </div>
          </div>

     

          {/* 5th Card */}
          <div>
            {/* <div className="card-list w-full max-w-[400px] mx-auto">
              <article className="card bg-white shadow-md hover:shadow-lg focus-within:shadow-lg transition ease-in-out duration-150 rounded-2xl overflow-hidden p-5 relative transform hover:translate-y-[-5px]">
                <figure className="card-image rounded-lg overflow-hidden">
                  <img
                    src={technicalsupport}
                    alt="An orange painted blue, cut in half laying on a blue background"
                    className="w-full h-auto"
                  />
                </figure>
                <div className="card-header mt-6 flex items-center justify-between">
                  <a
                    href="#"
                    className="font-semibold text-xl leading-5 pr-4 text-gray-900 relative focus:outline-none focus:ring focus:ring-offset-2"
                  >
                    Technical Support
                  </a>
                </div>
                <div className="card-footer mt-5 border-t border-gray-300 pt-5 flex items-center flex-wrap"></div>
              </article>
            </div> */}
          </div>

          {/* Additional cards... */}
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};
// Tailwind CSS (using inline style for fade-in)
const fadeInStyles = `
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fade-in 1s ease-in;
}
`;

// Append the style to the document
const styleElement = document.createElement("style");
styleElement.textContent = fadeInStyles;
document.head.appendChild(styleElement);

export default OurService;
